import './App.css';
import { Link, Route } from 'wouter';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { useState } from 'react';
import ModalDataProtection from './components/ModalDataProtection';
import Main from './components/Main';
import Impressum from './components/Impressum';

function App() {
	// ------------------------------------------- ROUTER --------------------------------

	// ------------------------------------------- STATE FUNCTIONS --------------------------------
	const [isOpen, setIsOpen] = useState(false);
	const [modalProps, setModalProps] = useState({});
	const [NavbarOpen, setNavbarOpen] = useState(false);

	function openModal(props) {
		setModalProps(props);
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}
	function toggleNavbar(props) {
		setNavbarOpen(!NavbarOpen);
	}
	// ------------------------------------------- STATE FUNCTIONS --------------------------------

	return (
		<div className="m-auto min-h-full w-screen  bg-gradient-to-b from-sky-950 via-sky-600 to-sky-950 ">
			<section className=" flex flex-col  max-w-[1280px] mx-auto ">
				<Navbar toggleNavbar={toggleNavbar} NavbarOpen={NavbarOpen} />
				<Route path="/datenschutz" component={ModalDataProtection} />
				<Route path="/impressum" component={Impressum} />
				<Route
					path="/"
					component={() => (
						<Main
							openModal={openModal}
							closeModal={closeModal}
							NavbarOpen={NavbarOpen}
							isOpen={isOpen}
							modalProps={modalProps}
						/>
					)}
				/>
				<Footer />
			</section>
		</div>
	);
}

export default App;
