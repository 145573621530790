import {
	InsuranceIcon,
	PropertyIcon,
	RealEstateIcon,
	RegenbogenIcon,
	RegenbogenLogoIcon
} from './Icons';

export default function Insurance() {
	return (
		<div
			id="Versicherung"
			className="w-full h-full bg-white p-8 text-black flex flex-col justify-center items-center pb-14"
		>
			<header className=" text-2xl lg:text-3xl text-center font-thin text-blue-950 ">
				Versicherungen
			</header>
			<p className="max-w-[600px] text-slate-500 mt-8 text-sm lg:text-lg font-semibold  text-center">
				Kompetente Versicherungslösungen aus einem Maklerhaus. Mit
				unserem Partner Regenbogen Consulting GmbH finden wir nicht nur
				den optimalen Versicherungsschutz für Ihre Immobilie, sondern
				sorgen auch für eine reibungslose Schadenregulierung.
			</p>
			<div className="object-cover max-w-[80%] my-8">
				<img src="images/regenbogen123.png" />
			</div>
			<a
				href="https://www.regenbogen-gmbh.de"
				target="_blank"
				className="text-sky-950 text-center text-pretty hover:underline   "
			>
				Weiter zu unseren Versicherungsexperten
			</a>
		</div>
	);
}
